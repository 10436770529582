<template>
  <div class="animated">
    <div id="snackbar"></div>
    <b-row>
      <b-col sm="12">
        <b-card>
          <div slot="header">
            <strong>Screen Seat layout</strong>
            <small>Form</small>
          </div>

          <b-form v-on:submit.prevent.self="submit">
            <b-row>
              <b-col sm="3">
                <b-form-group>
                  <label for="organization">
                    Organization
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-select
                    required
                    @change.native="getCinemaList($event.target.value)"
                    v-model="OrganizationID"
                    id="organizations"
                    :plain="true"
                    value-field="OrganizationID"
                    text-field="OrganizationName"
                    :options="organizations"
                  >
                    <template slot="first">
                      <option :value="0" disabled></option>
                        -- Select Organization --
                      </option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>

              <b-col sm="3">
                <b-form-group>
                  <label for="cinema">
                    Cinema
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-select
                    required
                    @change.native="getScreenList($event.target.value)"
                    v-model="CinemaID"
                    id="cinemas"
                    :plain="true"
                    value-field="CinemaID"
                    text-field="CinemaName"
                    :options="cinemas"
                  >
                    <template slot="first">
                      <option :value="0" disabled>-- Select Cinema --</option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>

              <b-col sm="3">
                <b-form-group>
                  <label for="screen">
                    Screen
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-select
                    @change.native="getCinemaClassList($event.target.value)"
                    v-model="seat.ScreenID"
                    id="screens"
                    :plain="true"
                    value-field="ScreenID"
                    text-field="ScreenName"
                    :options="screens"
                  >
                    <template slot="first">
                      <option :value="0" disabled>-- Select Screen --</option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row id="showlayout">
              <b-col sm="6">
                <b-form-group>
                  <label for="screen">
                    Grid Dimensions (Column X Row)
                    <span class="text-danger">*</span>
                  </label>
                  <div class="d-flex">
                    <b-form-input
                      type="number"
                      step="any"
                      v-model="sncols"
                    ></b-form-input
                    >X
                    <b-form-input
                      type="number"
                      step="any"
                      maxlength="1"
                      v-model="snrows"
                    ></b-form-input>
                  </div>
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group class="mt-4">
                  <b-button
                    :disabled="snrows != 0 && sncols != 0 ? false : true"
                    @click="generateLayout()"
                    size="sm"
                    variant="primary"
                  >
                    <i class="fa fa-dot-circle-o"></i> Generate
                  </b-button>
                </b-form-group>
              </b-col>
            </b-row>
            <div id="sngrid" class="sngrid">
              
              <br />
              <b-row  id="stickyRow"
              >
              <b-col sm="1">
                <b-form-group>
                  <label for="screen">
                    Row ID
                    <span class="text-danger">*</span>
                  </label>
                  <div class="d-flex">
                    <b-form-input
                      type="number"
                      step="any"
                      v-model="sbrow"
                    ></b-form-input>
                   
                  </div>
                </b-form-group>
              </b-col>
              <b-col sm="1">
                <b-form-group>
                  <label for="screen">
                    Colum ID
                    <span class="text-danger">*</span>
                  </label>
                  <div class="d-flex">
                    <b-form-input
                      type="number"
                      step="any"
                      v-model="sbcol"
                    ></b-form-input
                    >
                    
                  </div>
                </b-form-group>
              </b-col>
              <b-col sm="2">
                <b-form-group>
                  <label for="screen">
                    Name
                    <span class="text-danger">*</span>
                  </label>
                  <div class="d-flex">
                    <b-form-input
                      type="text"
                      step="any"
                      v-model="sbname"
                    ></b-form-input
                    >
                    
                  </div>
                </b-form-group>
              </b-col>
              <b-col sm="2">
                <b-form-group>
                  <label for="screen">
                    Class
                    <span class="text-danger">*</span>
                  </label>
                  <div class="d-flex">
                    <b-form-input
                      type="text"
                      step="any"
                      v-model="sbclass"
                    ></b-form-input
                    >
                    
                  </div>
                </b-form-group>
              </b-col>
              <b-col sm="2">
                <b-form-group>
                  <label for="screen">
                    Cinoco ID
                    <span class="text-danger">*</span>
                  </label>
                  <div class="d-flex">
                    <b-form-input
                      type="text"
                      step="any"
                      v-model="sbcineco"
                    ></b-form-input>
                    
                  </div>
                </b-form-group>
              </b-col>
              <b-col sm="2">
                <b-form-group>
                  <label for="organization">
                    SeatType
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-select
                    
                    v-model="sbstatus"
                    id="organizations"
                    :plain="true"
                    value-field="OrganizationID"
                    text-field="OrganizationName"
                  >
                    <template slot="first">
                      <option value="1">Seat</option>
                      <option value="0">No Seat</option>
                      <option value="3">Wheel</option>
                      <option value="5">Empty</option>
                      
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col sm="2">
                <b-form-group>
                  <label for="organization">
                    SeatType
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-select
                    
                    v-model="sbtype"
                    id="organizations"
                    :plain="true"
                    value-field="OrganizationID"
                    text-field="OrganizationName"
                  >
                    <template slot="first">
                      <option v-for="(cat, catindex) in seatLayoutType" :key="catindex"  :value="cat.SeatClassID">{{cat.Label}}</option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
              </b-row>
              <br />
              
              <div class="layout" :key="update">
                  <table class="table seats" id="seatNumber" style="width: 590px;">
                    <tbody  v-for="(cat, catindex) in seatLayoutType" :key="catindex">

                      <tr  isbalcony="False" isorchestra="False" isspecialseat="False">
                        <td>
                          {{cat.Label}}
                        </td>
                      </tr>

                      <tr  v-for="(row, rowindex) in screeenLayoutData[catindex]" :key="rowindex"  isbalcony="False" isorchestra="False" isspecialseat="False">
                        <td v-for="(col, colindex) in screeenLayoutData[catindex][rowindex]" :key="colindex" :class="getClass(catindex,rowindex,colindex)" v-on:click="colClick(catindex,rowindex,colindex)">
                          {{col.Status==1?"A":col.Status==0?"N":col.Status==5?"E":"W"}}
                          <br>
                          <br>
                          {{col.SeatName}}
                        </td>
                        <td >
                         <input type="text" class="sbinput" v-model="colid"/>
                         <div class="btn btn-success" v-on:click="SBAddNEWCol(catindex,rowindex)">Add Col</div>
                        </td>
                      </tr>
                     

                    </tbody>
                </table>
            </div>
            </div>
            <b-row>
              <b-col sm>
                <b-button
                  :disabled="submitButtonFalg"
                  type="submit"
                  size="sm"
                  variant="primary"
                >
                  <i class="fa fa-dot-circle-o"></i> Submit
                </b-button>
              </b-col>
              <b-col sm="6">
                <b-button @click="resetValues" size="sm" variant="success">
                  <i class="fa fa-dot-circle-o"></i> Reset
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      id="ModalConfirmationOnSubmit"
      ref="ModalConfirmationOnSubmit"
      @ok="handleModalOkay"
      :title="modalObject.title"
      class="center-footer"
      footer-class="justify-content-center border-0"
      cancel-variant="outline-danger"
      ok-variant="outline-success"
      centered
      hide-header
      no-close-on-esc
      no-close-on-backdrop
    >
      <div class="d-block text-center">
        <div>{{ modalObject.message }}</div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import OrganizationService from "@/services/OrganizationService";
import CinemaService from "@/services/CinemaService";
import ScreenService from "@/services/ScreenService";
import { Switch as cSwitch } from "@coreui/vue";
import { lstat } from "fs";

export default {
  name: "ScreenSeatLayout",
  components: {
    cSwitch,
  },
  data() {
    return {
      sbcat: -1,
      sbrow1: -1,
      sbcol1: -1,
      sbind: -1,
      sbrow: 0,
      sbcol: 0,
      sbtype: 0,
      sbstatus: 0,
      sbname: "",
      sbclass: "",
      sbcineco: "",
      update: 0,
      colid: 0,
      // Saif New
      lastInputChangedNumber: null,
      submitButtonFalg: true,
      setFlagsTypes: [],
      seatsStatusList: [
        {
          text: "S",
          value: "1",
        },
        {
          text: "N",
          value: "0",
        },
        {
          text: "W",
          value: "3",
        },
      ],
      seatsOptios: [
        {
          text: "Select Movie",
          value: "0",
        },
      ],
      // Saif New

      status: {
        showInput: false,
      },
      seat: {
        SeatLayouts: [],
        ScreenID: 0,
        Rows: "",
        Columns: "",
      },
      OrganizationID: 0,
      CinemaID: 0,
      rows: [],
      cols: [],
      // seatStatus: [],
      organizations: [],
      setFlags: [],
      cinemas: [],
      screens: [],
      rowNames: [],
      addCol: [],
      snrows: 0,
      sncols: 0,
      mainData: {
        seatData: [],
      },
      screeenLayoutData: [],
      seatLayoutType: [],
    };
  },
  beforeMount() {
    this.getCinemaList();
    this.getScreenList();
    // getSeatLayout();
  },
  mounted() {
    //   this.getCountries()
    this.$route.query.ScreenID
      ? (document.getElementById("sngrid").style.display = "block")
      : (document.getElementById("sngrid").style.display = "none");
    this.getCinemaList();
    this.getScreenList();
    this.getOrganizationList();
    this.getSeatLayout();

    window.document.body.onscroll = function () {
      const element = document.getElementById("stickyRow");
      if (window.pageYOffset > 290) {
        console.log(window.pageYOffset);
        element.classList.add("sticky-row");
      } else if (window.pageYOffset <= 200) {
        element.classList.remove("sticky-row");
      }
    };
  },
  methods: {
    getClass(catindex, rowindex, colindex) {
      if (
        catindex == this.sbcat &&
        this.sbrow1 == rowindex &&
        this.sbcol1 == colindex
      ) {
        return "seat available sbselected";
      }
      return "seat available";
    },
    colClick(catindex, rowindex, colindex) {
      if (this.sbcat != -1) {
        let temp1 =
          this.screeenLayoutData[this.sbcat][this.sbrow1][this.sbcol1];
        temp1.Row = this.sbrow;
        temp1.Column = this.sbcol;
        temp1.SeatName = this.sbname;
        temp1.classname = this.sbclass;
        temp1.cineco_no = this.sbcineco;
        temp1.Status = this.sbstatus;
        temp1.SeatClassID = this.sbtype;

        this.screeenLayoutData[this.sbcat][this.sbrow1][this.sbcol1] = temp1;
      }

      let temp = this.screeenLayoutData[catindex][rowindex][colindex];
      console.log("first", temp);

      this.sbcat = catindex;
      this.sbrow1 = rowindex;
      this.sbcol1 = colindex;

      this.sbrow = temp.Row;
      this.sbcol = temp.Column;
      this.sbname = temp.SeatName;
      this.sbclass = temp.classname;
      this.sbcineco = temp.cineco_no;

      this.sbstatus = temp.Status;
      this.sbtype = temp.SeatClassID;
      console.log("this.sbtype ", this.sbtype);
    },
    SBAddNEWCol(catindex, rowindex) {
      console.log("sb1 ", this.colid);
      let temp = this.screeenLayoutData[catindex][rowindex];
      let temp3 = [];
      let add = 0;
      for (var i = 0; i < temp.length; i++) {
        let values = temp[i];
        console.log("values", values);
        if (i == this.colid) {
          temp3.push({
            Column: parseInt(values.Column),
            Row: values.Row,
            SeatName: "",
            Status: 1,
            SeatlayoutID: "",
            SeatClassID: values.SeatClassID,
            cineco_no: "",
          });
          add = 1;
        }
        temp3.push({
          Column: parseInt(values.Column) + add,
          Row: values.Row,
          SeatName: values.SeatName,
          Status: values.Status,
          SeatlayoutID: values.SeatlayoutID,
          SeatClassID: values.SeatClassID,
          cineco_no: values.cineco_no,
        });
      }
      console.log("sb1 temp3", temp3);
      this.screeenLayoutData[catindex][rowindex] = temp3;
      this.update += 1;
    },

    addNewCol(index) {
      if (this.addCol[index] == -1) {
        alert("Please select the position");
      } else {
        console.log("first", this.addCol[index]);
        // numbers.splice(2, 0, 'three');
        let values = this.mainData.seatData[index].values;
        let obj = {
          classname: "seat",
          key: "",
          name: "",
          number: 0,
          type: "",
          value: true,
        };
        let temp = [];
        let add = 0;
        for (var i = 0; i < values.length; i++) {
          if (i == this.addCol[index]) {
            obj.number = index;
            obj.classname = "seatnew";
            obj.cineco_no = "";
            temp.push(obj);
            add = 1;
          }
          temp.push({
            classname: values[i].classname,
            key: values[i].key,
            name: values[i].name,
            number: values[i].number + add,
            type: values[i].type,
            value: values[i].value,
            cineco_no: values[i].cineco_no,
          });
        }
        console.log("mainData.seatData", this.mainData.seatData[index].values);
        this.mainData.seatData[index].values = temp;
        // mainData.seatData;
      }
    },
    setSeatLayoutData() {
      let layoutMainData = this.mainData.seatData;
      let layoutScreenData = this.screeenLayoutData;
      let parsedLayoutData = [];
      this.seatLayoutType.map((z) => {
        let temp = {};
        temp["text"] = z.Label;
        temp["value"] = z.SeatClassID;
        this.setFlags.push(temp);
      });

      layoutScreenData.map((l_s) => {
        l_s.map((y) => {
          parsedLayoutData.push(y);
        });
      });

      parsedLayoutData.map((x) => {
        x["Label"] = "";
        this.seatLayoutType.map((y) => {
          if (y.SeatClassID == x.SeatClassID) {
            x["Label"] = y.Label;
          }
        });
      });
      //parsedLayoutData gives data from api as object

      console.log({ parsedLayoutData });

      parsedLayoutData.map((x) => {
        layoutMainData.map((y) => {
          if (x.SeatName.charAt(0) == y.char) {
            y.values.map((z) => {
              if (z.number == x.Column) {
                console.log("first", x);
                z.type = x.SeatClassID;
                z.value = x.Status;
                z.classname = x.classname;
                z.cineco_no = x.cineco_no;
                console.log(z, "cineco_no");
              }
            });
          }
        });
      });
      this.mainData.seatData = layoutMainData;
      console.log(this.mainData.seatData, "this.mainData.seatData");
    },
    getSeatLayout() {
      if (this.$route.query.ScreenID) {
        ScreenService.getScreenSeatLayoutData({
          ScreenID: this.$route.query.ScreenID,
        })
          .then((res) => {
            console.log(this.mainData.seatData, "seat gen data");
            let { data } = res;
            // this.screeenLayoutData = data.SeatLayouts;
            let temp2 = data.SeatLayouts;
            let temp = data.SeatTypes;
            let temp3 = [];
            console.log("sb1", temp2);
            for (let i = 0; i < temp.length; i++) {
              console.log("sb1", temp2[i].Seats);
              let seats = temp2[i].Seats;
              let seatsrow = [];
              let cols = [];
              let row = -1;
              for (let j = 0; j < seats.length; j++) {
                if (row == -1) {
                  row = seats[j].Row;
                }
                let newrow = seats[j].Row;
                if (row != newrow) {
                  seatsrow.push(cols);
                  row = seats[j].Row;
                  cols = [];
                }
                cols.push(seats[j]);
              }
              seatsrow.push(cols);

              temp3.push(seatsrow);
            }
            console.log("sb1 temp3", temp3);
            this.screeenLayoutData = temp3;
            this.seatLayoutType = data.SeatTypes;
            let tempStorageData = JSON.parse(
              localStorage.getItem("dropDownCinema")
            );

            let temp1 = [];
            for (let i = 0; i < temp.length; i++) {
              temp1.push({ text: temp[i].Label, value: temp[i].SeatClassID });
            }
            this.setFlagsTypes = temp1;

            // this.setFlagsTypes.push({
            //     text: x.SeatClassName,
            //     value: x.SeatClassID,
            //   });
            this.OrganizationID = res.data.OrganizationID;
            this.CinemaID = this.$route.query.CinemaID;
            this.seat.ScreenID = this.$route.query.ScreenID;
            this.snrows = res.data.Row;
            this.sncols = res.data.Column;
            this.generateLayout();
            this.setSeatLayoutData();
          })
          .catch((err) => {
            console.log({ err });
          });
      }
    },
    resetValues: function () {
      this.seat = {
        SeatLayouts: [],
        ScreenID: 0,
        Rows: "",
        Columns: "",
      };
      this.submitButtonFalg = true;
      this.setFlags = [];
      this.setFlagsTypes = [];
      this.cinemas = [];
      this.screens = [];
      this.CinemaID = 0;
      this.seat.ScreenID = 0; //
      this.OrganizationID = 0;
      this.rowNames = [];
      this.snrows = 0;
      this.sncols = 0;
      this.mainData.seatData = [];
      document.getElementById("sngrid").style.display = "none";
    },
    resetSeatLayout: function () {
      this.submitButtonFalg = true;
      this.rowNames = [];
      this.snrows = 0;
      this.sncols = 0;
      this.setFlags = [];
      this.setFlagsTypes = [];
      this.mainData.seatData = [];
      document.getElementById("sngrid").style.display = "none";
    },
    getOrganizationList: function () {
      this.resetSeatLayout();

      this.organizations = [];
      OrganizationService.getOrganization()
        .then((response) => {
          const { data } = response;
          if (data.Status && data.OrganizationList.length > 0) {
            this.organizations = data.OrganizationList;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch((error) => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    getCinemaList: function (OrgID) {
      this.resetSeatLayout();
      this.cinemas = [];
      this.screens = [];
      this.CinemaID = 0;
      this.seat.ScreenID = 0;
      // let res = await CinemaService.getCinemas(e);
      let payload = {
        OrgID,
      };
      CinemaService.getCinemas(payload)
        .then((response) => {
          const { data } = response;
          if (data.Status && data.CinemaList.length > 0) {
            this.cinemas = data.CinemaList;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch((error) => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    showInputs: function (status) {
      this.status.showInput = !status;
    },
    generateLayout: function () {
      if (this.validation()) {
        this.submitButtonFalg = false;
        document.getElementById("sngrid").style.display = "block";
        this.mainData.seatData = [];
        this.rowNames = [];
        this.addCol = [];

        let snrows = this.snrows;
        snrows++;

        for (var i = 0, c = 65; i < snrows; i++) {
          let string = "N/A";
          if (i != 0) {
            string = String.fromCharCode(c);
            c++;
          }
          this.rowNames.push(string);
          this.addCol.push(-1);
          this.mainData.seatData.push({
            char: string,
            values: [],
            flag: "",
          });
          for (var j = 0; j < this.sncols; j++) {
            let Obj = {};
            Obj["key"] = string + "_" + i + "_" + j;
            Obj["value"] = "1";
            Obj["number"] = j + 1;
            Obj["name"] = string + (j + 1);
            Obj["type"] = "";
            this.mainData.seatData[i].values.push(Obj);
          }
        }
        console.log(this.mainData.seatData, "this.mainData");
        console.log("rowNames", this.rowNames);
        //    this.mainData.seatData = rowval;
        //    this.mainData.disabledFlag = Array(rowval.length).fill(false)
        // console.log("rowval :", this.mainData.seatData);
      } else {
        this.mainData.seatData = [];
        this.rowNames = [];
        document.getElementById("sngrid").style.display = "none";
      }
    },
    getScreenList: function (CinemaID) {
      this.screens = [];
      this.seat.ScreenID = 0;

      let payload = {
        CinemaID,
        SeatLayout: this.$route.query.ScreenID
          ? parseInt(this.$route.query.ScreenID) > 0
            ? false
            : true
          : true,
      };
      ScreenService.getScreens(payload)
        .then((response) => {
          const { data } = response;
          if (data.Status && data.ScreenList.length > 0) {
            this.screens = data.ScreenList;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch((error) => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    //  On Select Screen
    getCinemaClassList: function (ScreenID) {
      this.resetSeatLayout();
      this.setFlags = [];
      this.setFlagsTypes = [];
      let payload = {
        ScreenID,
      };

      CinemaService.getCinemaClasses(payload)
        .then((response) => {
          const { data } = response;
          if (data.Status && data.CinemaClassList.length > 0) {
            data.CinemaClassList.map((x) => {
              this.setFlagsTypes.push({
                text: x.SeatClassName,
                value: x.SeatClassID,
              });
              this.setFlags.push({
                text: x.SeatClassName.charAt(0),
                value: x.SeatClassID,
              });
            });
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch((error) => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    validation: function () {
      let value = false;
      if (this.OrganizationID == 0) {
        this.showMessageSnackbar("Please Select the Organization");
        return false;
      } else if (this.CinemaID == 0) {
        this.showMessageSnackbar("Please Select the Cinema");
        return false;
      } else if (this.seat.ScreenID == 0) {
        this.showMessageSnackbar("Please Select the Screen.");
        return false;
      } else if (this.snrows == 0) {
        this.showMessageSnackbar("Please Select Grid Row");
        return false;
      } else if (this.snrows > 50) {
        this.showMessageSnackbar("Please Select Grid Row must be minimum 25");
        return false;
      } else if (this.sncols == 0) {
        this.showMessageSnackbar("Please Select Grid Column");
        return false;
      } else if (this.sncols > 50) {
        this.showMessageSnackbar(
          "Please Select Grid Column must be minimum 25"
        );
        return false;
      } else {
        return true;
      }
    },
    submit: function () {
      if (this.validation()) {
        let payload = {
          title: "Confirmation",
          message: "Please re-check the data before submit",
        };
        this.showSubmitConfirmation(payload);
      }
    },
    submitFinalData: function () {
      this.closeModal("ModalConfirmationOnSubmit");

      this.seat.Rows = this.snrows;
      this.seat.Columns = this.sncols;

      if (this.sbcat != -1) {
        let temp1 =
          this.screeenLayoutData[this.sbcat][this.sbrow1][this.sbcol1];
        temp1.Row = this.sbrow;
        temp1.Column = this.sbcol;
        temp1.SeatName = this.sbname;
        temp1.classname = this.sbclass;
        temp1.cineco_no = this.sbcineco;
        temp1.Status = this.sbstatus;
        temp1.SeatClassID = this.sbtype;

        this.screeenLayoutData[this.sbcat][this.sbrow1][this.sbcol1] = temp1;
      }

      console.log("this.seat", this.seat);
      let array = [];
      for (var i = 0; i < this.screeenLayoutData.length; i++) {
        for (var j = 0; j < this.screeenLayoutData[i].length; j++) {
          for (var k = 0; k < this.screeenLayoutData[i][j].length; k++) {
            array.push(this.screeenLayoutData[i][j][k]);
          }
        }
      }

      this.seat.SeatLayouts = array;
      console.log("Json Final data", JSON.stringify(this.seat));

      // let finalArray = [];
      // array.map((x, index) => {
      //   if (index != 0) {
      //     x.values.map((y, yindex) => {
      //       console.log("y", y.classname, {
      //         row: index,
      //         columnName: y.name,
      //         columnNo: yindex + 1,
      //         className: y.classname ? y.classname : "seat",
      //         type: parseInt(y.type),
      //         status: y.value,
      //       });
      //       finalArray.push({
      //         row: index,
      //         columnName: y.name,
      //         columnNo: yindex + 1,
      //         className: y.classname ? y.classname : "seat",
      //         cinecoNo: y.cineco_no ? y.cineco_no : "",
      //         type: parseInt(y.type),
      //         status: y.value,
      //       });
      //     });
      //     // finalArray.push(obj);
      //   }
      // });
      // this.seat.SeatLayouts = finalArray;
      // // console.log("Here Final Lay>>", finalArray);
      // // console.log("Here Final Lay>>", JSON.stringify(this.seat));
      // console.log("Json Final data", JSON.stringify(this.seat));
      ScreenService.addSeatLayout(this.seat)
        .then((response) => {
          const { data } = response;
          this.showMessageSnackbar(data.Message);
          if (data.Status) {
            this.resetValues();
          }
        })
        .catch((error) => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    onFocusClassName: function (event, rowIndex, colIndex, value) {
      // console.log("check focus", event, rowIndex, colIndex, value);
      this.lastInputChangedNumber =
        this.mainData.seatData[rowIndex].values[colIndex].classname;
    },
    onFocusCinecoName: function (event, rowIndex, colIndex, value) {
      // console.log("check focus", event, rowIndex, colIndex, value);
      this.lastInputChangedNumber =
        this.mainData.seatData[rowIndex].values[colIndex].cineco_no;
    },
    onChangeCinecoName: function (event, rowIndex, colIndex, value) {
      console.log("onChangeCinecoName", event);
      if (event.target.value.length > 0) {
        this.mainData.seatData[rowIndex].values[colIndex].cineco_no =
          event.target.value;
      } else {
        this.mainData.seatData[rowIndex].values[colIndex].cineco_no =
          event.target.value;
      }

      console.log(
        "this.mainData.seatData[rowIndex]",
        this.mainData.seatData[rowIndex]
      );
    },
    onChangeClassName: function (event, rowIndex, colIndex, value) {
      if (event.target.value.length > 0) {
        this.mainData.seatData[rowIndex].values[colIndex].classname =
          event.target.value;
      } else {
        this.mainData.seatData[rowIndex].values[colIndex].classname =
          event.target.value;
      }

      console.log(
        "this.mainData.seatData[rowIndex]",
        this.mainData.seatData[rowIndex]
      );
    },
    onFocusShowName: function (event, rowIndex, colIndex, value) {
      // console.log("check focus", event, rowIndex, colIndex, value);
      this.lastInputChangedNumber =
        this.mainData.seatData[rowIndex].values[colIndex].number;
    },
    onChangeShowName: function (event, rowIndex, colIndex, value) {
      console.log(
        "event, rowIndex, colIndex, value :",
        event,
        rowIndex,
        colIndex,
        value
      );
      if (event.target.value.length > 0) {
        this.mainData.seatData[rowIndex].values[colIndex].name =
          event.target.value;
        this.mainData.seatData[rowIndex].values[colIndex].value = "1";
      } else {
        this.mainData.seatData[rowIndex].values[colIndex].name =
          event.target.value;
        this.mainData.seatData[rowIndex].values[colIndex].value = "0";
      }

      console.log(
        "this.mainData.seatData[rowIndex]",
        this.mainData.seatData[rowIndex]
      );
    },
    //
    updateSeatLayout: function (value, rowIndex, colIndex, number) {
      console.log(
        "value, rowIndex, colIndex, number",
        value,
        rowIndex,
        colIndex,
        number
      );
      // console.log("Herer >> ", value, rowIndex, colIndex, number);
      // console.log(">>>>", this.mainData.seatData);
      if (value.length == 0) {
        //   console.log("this.mainData.seatData :", this.mainData.seatData);
        this.setValues(false, value, colIndex);
      } else {
        this.setValues(true, value, colIndex);
        // this.mainData.seatData.map((x, index) => {
        //   // console.log("Here index and res", x, index)
        //   x.values[colIndex].value = true;
        //   let c = 1;
        //   for (let i = 0; i < x.values.length; i++) {
        //     if (x.values[i].number !== "") {
        //       x.values[i].number = c;
        //       x.values[i].name = this.rowNames[index] + c;
        //       c++;
        //     }
        //   }
        //   x.values[colIndex].number = value;
        // });
      }
    },
    setValues: function (status, value, colIndex) {
      // console.log("status, value, colIndex :", status, value, colIndex);
      this.mainData.seatData.map((x, index) => {
        x.values[colIndex].value = status ? "1" : "0"; // status;
        x.values[colIndex].number = value;
        x.values[colIndex].name = value;

        for (let i = 0; i < x.values.length; i++) {
          if (x.values[i].number !== "") {
            // console.log("check Value >>", this.rowNames[index], c);
            // x.values[i].number = c;
            // x.values[i].name = this.rowNames[index] + c;
            x.values[i].number = x.values[i].number;
            x.values[i].name = x.char + x.values[i].number;
          }
        }
        x.values[colIndex].number = value;
      });
    },
    // Check Status Hanndler
    statusHandler: function (rowIndex, colIndex, status) {
      // console.log("main Data >>>", this.mainData.seatData)
      // console.log("We are inside the handler",Object.keys(this.mainData.seatData[rowIndex].values[colIndex]))
      // console.log(">>>>>>>>>",)
      // let getKey = Object.keys(this.mainData.seatData[rowIndex].values[colIndex]);
      // console.log("Herer >>", status, !status)
      this.mainData.seatData[rowIndex].values[colIndex].value = status;
      // console.log("getetetet", this.mainData.seatData);
    },
    onChangeType: function (e, rowIndex) {
      this.mainData.seatData[rowIndex].values.map((x) => {
        x.type = e;
      });
    },
    onAddCol: function (e, rowIndex) {
      this.addCol[rowIndex] = e;
      console.log("this.addCol[rowIndex]", this.addCol[rowIndex]);
    },
    // On Select Flag
    onSelecteFlag: function (e, rowIndex) {
      // this.mainData.seatData[rowIndex].flag = e;
      // console.log("Check After Select>>", this.mainData.seatData);
    },
    // Onchange Input Char // Disabled the all row
    onChangeChar: function (e, rowIndex) {
      // if()
      // console.log("Here >> ", e, e.length, rowIndex, this.mainData.seatData);
      this.mainData.seatData[rowIndex].char = e;
      if (e.length == 0) {
        this.mainData.seatData[rowIndex].values.map((x, index) => {
          // let obj = Object.keys(x)
          // x.value = false;
          x.value = false;
          x.name = e + x.number;
        });
        // console.log("here >> ", this.mainData);
      } else {
        this.mainData.seatData[rowIndex].values.map((x, index) => {
          // let obj = Object.keys(x)
          // x.value = true;
          x.value = true;
          x.name = e + x.number;
        });
      }
    },
    // Show Confirmmation Dialog
    handleModalOkay: function (evt) {
      // evt.preventDefault();
      this.modalObject.count = 1;
      this.submitFinalData();
    },
    showSubmitConfirmation: function (payload) {
      this.modalObject.title = payload.title; // "Confirmation";
      this.modalObject.message = payload.message; // "Please re-check the data before submit";
      this.openModal("ModalConfirmationOnSubmit");
    },
    openModal: function (ref) {
      this.$refs[ref].show();
    },
    closeModal: function (ref) {
      this.$refs[ref].hide();
      this.modalObject.title = ""; // "Confirmation";
      this.modalObject.message = ""; // "Please re-check the data before submit";
    },
    // Show Confirmmation Dialog End
    // Show Error Message
    showMessageSnackbar: function (message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function () {
        x.className = x.className.replace("show", "");
      }, 3000);
    },
  },
};
</script>
<style>
.sninneractive {
  background-color: red !important;
}

.sninner {
  background-color: gray;
  width: 30px;
  height: 30px;
}
.sninnertext input {
  width: 100%;
}
.sninnertext {
  width: 30px;
  height: 30px;
}
.snsquare {
  padding: 2px;
}
.grid {
  height: 300px;
}
#showlayout + .grid {
  width: 120px;
  height: 120px;
  background: rgba(0, 0, 0, 0.2);
  position: relative;
  padding: 2px;
  display: none;
}

#showlayout:focus + .grid {
  display: block;
}

#showlayout + .grid .row:first-child .square:first-child .inner {
  background: rgba(0, 0, 0, 0.4);
}

#showlayout + .grid .square:first-child {
  margin-top: 0;
}

#showlayout + .grid .row {
  height: calc(100% / 5);
}
#showlayout + .grid .square {
  height: 100%;
  width: calc(100% / 5);

  display: inline-block;
  margin: -2px 0;
  padding: 1px;
}
#showlayout + .grid .square:hover .inner,
#showlayout + .grid .square.highlight .inner {
  background: rgba(0, 0, 0, 0.4);
}

.inner {
  height: 100%;
  width: 100%;
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.2);
}

.layout {
  width: 100%;
}
#seatNumber {
  margin-left: auto;
  margin-right: auto;
}
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}

#seatNumber tr td:first-child,
#seatNumber tr td:last-child {
  text-align: center;
}

.layout .seat {
  width: 62px;
  height: 32px;
  font-size: 10px;
  padding-top: 8px;
  text-align: center;
  color: #2f2f2f;
  background: url(https://www.wanasatime.com/Images/WT-images/Seat-Available.png)
    no-repeat center center;
  background-size: 24px;
  cursor: pointer;
}

.sbinput {
  width: 20px;
}

.layout table tr {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.sbselected {
  border-color: red !important;
}
.sticky-row {
  position: fixed !important;
  top: 60px;
  background: white;
  width: 82%;
  border: 2px solid grey;
  padding: 10px 0 0 0;
}
.sngrid {
  overflow-x: auto;
}
</style>
